import React, { useEffect, useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Main from '../mennewsdeatils/Main'
import VoidList from '../../voidList';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { getAllNews } from '../../../api/api';
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import {
  ResultCardSkeleton
} from "../../../components/skeletonLoaders";


function NewsDetails() {
  const { uuid } = useParams();
  const [news, setNews] = useState({});
  const [reloadPage, setReloadPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    setLoading(true);
    getAllNews()


      .then((res) => {
        if (res.data?.data.length > 0) {
          setNews(res.data.data.find(n => n.attributes.uuid === uuid));
        }
      })
      .catch((error) => { setError(error); })
      .finally(() => {
        setLoading(false);
      });

  }, [reloadPage, uuid])

  // Effectuez les actions nécessitant news uniquement après sa mise à jour 
  if (news) {

    document.getElementById("title").innerHTML = `Article détaillé || ${news.attributes?.newstitle.toString().replace(/\s/g, '-')}`;
  }

  return (
    <>
      <Header />
      <div className="page-wrapper">
        {news && <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/news"
          secondPage="Blog"
          thirdPage="Article détaillé"
        /*  titledown={news.attributes?.newstitle.toString()} */

        />}
        {loading && !error && <ResultCardSkeleton />}
        {!loading && !error && Object.keys(news).length > 0 &&
          <Main mainDetailImage={`${apiConstant}${news.attributes?.mainDetailImage.data.attributes.url}`}
            newsDate={news.attributes?.newsDate}
            authorName={`${news.attributes?.createdBy.lastname} ${news.attributes?.createdBy.firstname}`}
            newstitle={news.attributes?.newstitle}
            newsContent={news.attributes?.newsContent}
            newsPublishedDate={`${news.attributes?.publishedAt} `}
            galleryImages={news.attributes?.galleryImages.data}
            uuid={news.attributes?.uuid}
            setReloadPage={setReloadPage}
          />}
        {!loading && !error && Object.keys(news).length === 0 && <VoidList
          message={"Désolé, Aucune Actualité n'est disponible. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
      </div>
    </>
  )
}

export default NewsDetails
