import React from 'react';
import Shapes01 from '../../assets/image/shapes/shape-1.png';
import Gallery01 from '../../assets/image/gallery/fomopea-king.jpg';
import Gallery02 from '../../assets/image/gallery/king-fomopea.jpg';
function Mayor() {
  return (
    <>
      <section className="mayor-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="mayor-box">
                <div className="section-title-box">
                  <div className="section-tagline">Chef FOMOPEA</div>
                  <h2 className="section-title">
                    Chef Supérieure du groupement FOMOPEA
                  </h2>
                  <p>
                    Neuvième de la dynastie royale FOMOPEA, Sa majesté est le
                    principal promoteur du développement du groupement bien sur
                    le plan économique que sur le plan culturel
                  </p>
                </div>

                <div className="mayor-icon-box">
                  <div className="mayor-icon">
                    <i className="flaticon-helping-hand" />
                  </div>

                  <h4 className="mayor-icon-title">
                    Afin de mener à bien sa mission, il se fait conseiller et
                    accompagner :
                  </h4>
                </div>
                <ul className="list-unstyled list-style-one">
                  <li>
                    <i className="fa-solid fa-circle-check" />
                    <p>Par l'ensemble de ses notables(natifs et nommés)</p>
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check" />
                    <p>Par l'A.D.G.F</p>
                  </li>

                  <li>
                    <i className="fa-solid fa-circle-check" />
                    <p> Très souvent par les autres membres du groupement</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mayor-img">
                <img src={Shapes01} className="floated-image-one" alt="img-7" />
                <img
                  src={
                    window.matchMedia('(max-width: 768px)').matches
                      ? Gallery02
                      : Gallery01
                  }
                  alt="img-8"
                />
                <div className="mayor-name">Sa majesté Tientcheu Justin </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mayor;
