import React from 'react'
import { Link } from 'react-router-dom'
import apiConstant from "../../../assets/constants/strapiBaseUrl"





function ProjectsList(props) {
    const { projectsList } = props;
    const truncateTitle = (title, maxLength) => {
        if (title.length <= maxLength) {
            return title;
        } else {
            // Couper la chaîne et ajouter "..." à la fin
            return title.slice(0, maxLength) + "...";
        }
    };




    return (
        <>
            <div className="table-container">
                {projectsList && projectsList.length > 0 && <table className="table">
                    <thead>
                        <tr>
                            <th>Nom du projet</th>
                            <th>Description</th>
                            <th>Date de fin de réalisation prévue</th>
                            <th>Budget prévisionnel</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                        {projectsList.map((item, index) => (
                            <tr key={index}>
                                {item.attributes.projectTitle && <td>{item.attributes.projectTitle}</td>}
                                {item.attributes.projectContent && <td>{item.attributes.projectContent}</td>}
                                {item.attributes.projectCompletionDate && <td> {new Date(item.attributes.projectCompletionDate).toLocaleDateString('fr-FR', {
                                    year: 'numeric',
                                    month: 'long',
                                })}</td>}
                                {item.attributes.projectEstimatedBudget && <td>{item.attributes.projectEstimatedBudget.toLocaleString('fr-FR', {
                                    style: 'currency',
                                    currency: 'XAF'
                                })}</td>}
                            </tr>
                        ))}

                    </tbody>
                </table>
                }
                {projectsList && projectsList.length > 0 && projectsList.map((item, index) => (<div className="mobile-list">

                    <div key={index} className="mobile-list-item">
                        {item.attributes.projectTitle && <div><u><b>Projet:</b></u> {item.attributes.projectTitle}</div>}
                        {item.attributes.projectContent && <div><u><b>Description:</b></u> {item.attributes.projectContent}</div>}
                        {item.attributes.projectCompletionDate && <div><u><b>Fin prévue:</b></u>  {new Date(item.attributes.projectCompletionDate).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                        })}</div>}
                        {item.attributes.projectEstimatedBudget && <div><u><b>Budget prév.:</b></u> {item.attributes.projectEstimatedBudget.toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'XAF'
                        })}</div>}
                    </div>





                </div>
                ))}
            </div>
        </>
    )
}

export default ProjectsList