import React, { useEffect } from 'react';
import Header from '../headerone/Main';
import Blog from '../menhome/Blog';
import OngoingProjects from '../menhome/OngoingProjects';
import Client from '../menhome/Client';
import Event from '../menhome/Event';
import Intro from '../menhome/Intro';
import Mayor from '../menhome/Mayor';
import Slider from '../menhome/Slider';
import { Helmet } from 'react-helmet';

function Main() {
  useEffect(() => {
    document.getElementById('title').innerHTML = 'Association pour le Développement du Groupement FOMOPEA';


  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Association pour le Développement du Groupement FOMOPEA" />
        <meta property="og:description" content="Présentation du groupement FOMOPEA tout court" />
        <meta property="og:image" content={`${window.location.origin}/logo_adgf_og.png`} />
        <meta property="og:url" content={`${window.location.origin}`} />

      </Helmet>
      <Header />

      <div className="page-wservrapper">
        <Slider />
        <Intro />
        {/*      <Service /> */}
        {/*  <Counter /> */}
        <Mayor />
        <OngoingProjects />
        <Event />
        {/* <Portfolio /> */}
        <Client />
        {/*   <Testimonial /> */}

        <Blog />
      </div>
    </>
  );
}

export default Main;
