import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllEvents } from '../../api/api';
import apiConstant from "../../assets/constants/strapiBaseUrl"
import {
    BlockListSkeleton
} from "../../components/skeletonLoaders";
import VoidList from '../voidList';


function Event() {
    const [eventsList, setEventsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getAllEvents()
            .then((res) => {
                if (res.data?.data.length > 0) {
                    setEventsList(res.data.data.sort((a, b) => new Date(b.attributes.newsDate) - new Date(a.attributes.newsDate)));
                }
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    const truncateTitle = (title, maxLength = 100) => {
        if (title.length <= maxLength) {
            return title;
        } else {
            // Couper la chaîne et ajouter "..." à la fin
            return title.slice(0, maxLength) + "...";
        }
    };
    return (
        <>
            <section className="event-section">
                <div className="container">
                    {loading && !error && <BlockListSkeleton />}
                    {!loading && !error && eventsList && eventsList.length > 0 &&
                        <div className="event-section-inner">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-title-box">
                                        <div className="section-tagline">Evènements récents</div>
                                        <h2 className="section-title">
                                            Explorez nos dernières activités
                                        </h2>
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    <div className="event-content-box">
                                        <div className="section-text">

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="row row-gutter-y-40">
                                <div className="col-xl-5">
                                    <div className="event-subscribe-card">
                                        <div className="event-details-card-title">
                                            <div className="event-icon">
                                                <i className="flaticon-envelope" />
                                            </div>

                                            <h5>Découvrir</h5>
                                            <p>Nos plus récents évènements</p>
                                        </div>

                                        <div className="event-details-card-content">
                                            <form action="assets/inc/sendemail.php" className="contact-form" method="post" >
                                                <div className="form-group">
                                                    <input type="email" id="phonenumber" className="input-text" placeholder="Numéro de téléphone" name="phonenumber" required="true" />
                                                </div>

                                                <button className="btn btn-primary w-100">Enregisrer</button>

                                                <p className="text-center">Partagez votre numéro pour être à jour sur nos évènements</p>
                                            </form>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-xl-7">

                                    {eventsList && eventsList.length > 0 &&
                                        eventsList.slice(0, 2).map((item, key) => (
                                            <div className="event-card">
                                            <div className="event-card-image">

                                                <div className="event-card-image-inner">
                                                    {item.attributes.uuid && <Link to={`/eventdetails/${item.attributes.uuid}`} >
                                                        {item.attributes.eventListImage && <img width="250"
                                                            height="250"
                                                            style={{ objectFit: 'cover' }} src={`${apiConstant}${item.attributes.eventListImage.data?.attributes.formats.small.url}`} className="img-fluid" alt="img-184" />}
                                                    </Link>}
                                                    <div className="event-card-meta">
                                                        <div className="event-meta-number">
                                                            {item.attributes.eventDate && <span>{`${new Date(item.attributes.eventDate).toLocaleDateString('fr-FR', {
                                                                day: 'numeric',
                                                            })}`}</span>}
                                                        </div>
                                                        <div className="event-meta-date">
                                                            {item.attributes.eventDate && <span>{`${new Date(item.attributes.eventDate).toLocaleDateString('fr-FR', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                            })}`}</span>}
                                                        </div>
                                                    </div>



                                                </div>

                                            </div>

                                            <div className="event-card-content">
                                                <div className="event-card-info">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <i className="fa-solid fa-clock" />
                                                            {item.attributes.eventDate && <span> {`${new Date(item.attributes.eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}</span>}
                                                        </li>

                                                        <li>
                                                            <i className="fa-sharp fa-solid fa-location-pin" />
                                                            {item.attributes.eventLocation && <span> {item.attributes.eventLocation}</span>}
                                                        </li>

                                                    </ul>

                                                </div>

                                                <div className="event-card-title">
                                                    <h4>
                                                        {item.attributes.uuid && <Link to={`/eventdetails/${item.attributes.uuid}`} >
                                                            {item.attributes.eventTitle && <span>{truncateTitle(item.attributes.eventTitle)}</span>}
                                                        </Link>}
                                                    </h4>
                                                </div>

                                            </div>

                                        </div>
                                        ))}


                                </div>

                            </div>

                        </div>}

                    {!loading && !error && (!eventsList || eventsList.length === 0) && <VoidList
                        message={"Désolé, Aucune Actualité n'est disponible. Merci de réessayer plus tard"}
                    />}
                    {error && <VoidList
                        message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
                    />}

                </div>
            </section>


        </>
    )
}

export default Event