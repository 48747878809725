import React, { useEffect, useState } from 'react';
import ProjectsList from '../menprojectsList/projectsList';
import VoidList from '../../voidList';
import {
    getAllProjects
} from "../../../api/api";
import {
    TabSkeleton
} from "../../../components/skeletonLoaders";


function ListOfProjects() {

  const [projectsList, setProjectsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllProjects()
      .then((res) => {
        if (res.data?.data.length > 0) {
            setProjectsList(res.data.data.sort((a, b) => new Date(b.attributes.projectCompletionDate) - new Date(a.attributes.projectCompletionDate)));
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);






  return (
    <>
        {loading && !error && <TabSkeleton />}
        {!loading && !error && projectsList && projectsList.length > 0 && <ProjectsList projectsList={projectsList} />}
        {!loading && !error && (!projectsList|| projectsList.length === 0) && <VoidList
          message={"Désolé, Aucun projet n'est disponible. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
   

    </>
  )
}

export default ListOfProjects