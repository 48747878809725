import React, { useEffect, useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Main from '../menrealisationdetails/Main'
import VoidList from '../../voidList';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { getAllRealisations } from '../../../api/api';
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import {
    ResultCardSkeleton
} from "../../../components/skeletonLoaders";


function RealisationsDetails() {
    const { uuid } = useParams();
    const [realisation, setRealisation] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log("uuid +++++++++++++",uuid )
        setLoading(true);
        getAllRealisations()
            .then((res) => {
                if (res.data?.data.length > 0) {
                    setRealisation(res.data.data.find(n => n.attributes.uuid === uuid));
                }
            })
            .catch((error) => { setError(error); })
            .finally(() => {
                setLoading(false);
            });
    }, [])

    console.log("realisation +++++++++++++",realisation)

    if (realisation) {

        document.getElementById("title").innerHTML = `A propos de la réalisation  || ${realisation.attributes?.realisationTitle.toString().replace(/\s/g, '-')}`;
    }


    return (
        <>
            <Header />

            <div className="page-wrapper">

                {realisation && <Bredcom
                    firstPageRoute="/"
                    firstPage="Accueil"
                    secondPageRoute="/realisations-and-projects"
                    secondPage="Réalisations et projets"
                    thirdPage="A propos"


                />}

                {loading && !error && <ResultCardSkeleton />}
                {!loading && !error && Object.keys(realisation).length > 0 &&
                    <Main mainDetailImage={`${apiConstant}${realisation.attributes?.mainDetailImage.data.attributes.url}`}
                        realisationCompletionDate={realisation.attributes?.realisationCompletionDate}
                        realisationTitle={realisation.attributes?.realisationTitle}
                        realisationContent={realisation.attributes?.realisationContent}
                        galleryImages={realisation.attributes?.galleryImages.data}
                        realisationEstimateBudget={realisation.attributes?.realisationEstimateBudget}
                    />}
                {!loading && !error && Object.keys(realisation).length === 0 && <VoidList
                    message={"Désolé, Cette réalisation n'est pas disponible. Merci de réessayer plus tard"}
                />}
                {error && <VoidList
                    message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
                />}
            </div>
        </>
    )
}

export default RealisationsDetails