import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import { Link } from 'react-router-dom'

// Composant représentant un site touristique individuel


// Composant pour afficher la liste des sites touristiques
const DestinationList = (props) => {
  const { destinationsList } = props;

  return (
    <section className="blog-section blog-section-two">
      <div className="container">
        <div className="row row-gutter-y-155">

      

          {destinationsList && destinationsList.length > 0 &&


            destinationsList.map((destination, index) => (

              <Col md={4} sm={6} xs={12} className="mb-4">
                <Card className="h-100 shadow">
                  {destination.attributes.uuid && <Link to={`/destinationdetails/${destination.attributes.uuid}`} >
                    {destination.attributes.listImage && <Card.Img variant="top" src={`${apiConstant}${destination.attributes.listImage.data?.attributes.formats.small.url}`} className="card-image" />}
                  </Link>}
                  <Card.Body>
                    {destination.attributes.uuid && <Link  style={{ color: 'inherit', textDecoration: 'none' }} to={`/destinationdetails/${destination.attributes.uuid}`} >
                      {destination.attributes.name && <Card.Title >{destination.attributes.name}</Card.Title>}
                    </Link>}
                    {destination.attributes.indication && <Card.Text>{destination.attributes.indication}</Card.Text>}
                  </Card.Body>
                </Card>
              </Col>
            ))
          }
        </div>

      </div>
    </section>
  );
};



export default DestinationList;
