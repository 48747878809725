import React , {useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom';
import VoidPage from '../components/pages/voidPage/voidPage';

import Footer from '../components/Footer/Main'
import Hometwo from '../components/hometwo/Main'
import Pageabout from '../components/pages/about/Main'
import Team from '../components/pages/team/Main'
import Teamdeatils from '../components/pages/Teamdeatils/Main'
import Portfolio from '../components/pages/Portfolio/Main'
import Portfoliodeatils from '../components/pages/portfoliodeatils/Main'
import Causes from '../components/pages/projects/Main'
import Causesdeatils from '../components/pages/causesdeatils/Main' 
import Services from '../components/pages/services/Main'
import Servicesdeatils from '../components/pages/servicesdeatils/Main'
import Departments from '../components/pages/departments/Main'
import Departmentdetails from '../components/pages/departmentdeatils/Main'
import Events from '../components/pages/events/eventList'

import Eventdeatils from '../components/pages/eventsdeatils/eventdetails'
import NewsList from '../components/pages/News/NewsList'
import NewsDetails from '../components/pages/newsdeatils/newsdetails'
import Contact from '../components/contact/Main'
import Home from '../components/home/Main'

import DestinationDetails from '../components/pages/destinationsdetails/destinationsdetails'
import Destinations from '../components/pages/destinations/Destinations'

import Underconstruction from '../components/pages/underconstruction/underconstruction'

import ProjectsTests from '../components/pages/projects/Main1'

import RealisationsProjects from '../components/pages/projects/Main'
import RealisationsDetails from '../components/pages/realisationdetails/realisationsdetails'


function Index() {

  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

  return (
    <>

        <Routes>
          <Route path="*" element={<VoidPage />} />
          <Route path="/" element={<Home />} />
          <Route path="/hometwo" element={<Hometwo />} />
          

          <Route path="/fomopea-history" element={<Underconstruction page='"Histoire de FOMOPEA"'/>} />

          <Route path="/fomopea-dynasty" element={<Underconstruction page='"La dynastie royale FOMOPEA"'/>} />
          <Route path="/chieftaincy" element={<Underconstruction page='"La chefferie et les notables de FOMOPEA"'/>} />
          <Route path="/neighborhoods" element={<Underconstruction page='"Les différents quartiers de FOMOPEA"'/>} />
          <Route path="/associations" element={<Underconstruction page='"La vie associative de FOMOPEA"'/>} />

          <Route path="/about-adgf" element={<Underconstruction page='"A propos de ADGF"'/>} />
          <Route path="/commissions-adgf" element={<Underconstruction page='"Les commissions de ADGF"'/>} />
          <Route path="/projects-adgf" element={<Underconstruction page='"Les projets de ADGF"'/>} />


          <Route path="/contact-us" element={<Underconstruction page='"La page de contact"'/>} />
          <Route path="/contribute" element={<Underconstruction page='" Promesses de contributions aux projets"'/>} />


          <Route path="/team" element={<Team />} />
          <Route path="/teamdetails" element={<Teamdeatils />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfoliodeatils" element={<Portfoliodeatils />} />
          <Route path="/causes" element={<Causes />} />
          <Route path="/causedetails" element={<Causesdeatils />} />
          <Route path="/services" element={<Services />} />
          <Route path="/servicedetails" element={<Servicesdeatils />} />
          <Route path="/departments" element={<Departments />} />
          <Route path="/departmentdetails" element={<Departmentdetails />} />

          
          <Route path="/events" element={<Events />} />
          <Route path="/eventdetails/:uuid" element={<Eventdeatils />} />
          <Route path="/news" element={<NewsList  />} />
          <Route exact  path="/newsdetails/:uuid" element={<NewsDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/destinationdetails/:uuid" element={<DestinationDetails />} />
          <Route path="/destinations" element={<Destinations />} />
          <Route path="/realisations-and-projects" element={<RealisationsProjects />} />
          <Route path="/realisationdetails/:uuid" element={<RealisationsDetails />} />
          

          <Route path="/tests" element={<ProjectsTests  />} />

        </Routes>

        <Footer/>

    </>
  )
}

export default Index