import React from 'react';

function Underconstruction(props) {
  // use material

  return (
    <div className={'col-12 col-md-10 mx-auto my-3 my-md-5'}>
      <div className={'underconstruction-icon mx-auto'} />
      <div className={'text-center'}>
        <p className={'text-bold my-3 text-100 text-md-150'}>{props.message}</p>
      </div>
    </div>
  );
}

export default Underconstruction;
