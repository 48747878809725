import React, {useEffect} from 'react'
import Header from '../../headerone/Main'


import Underconstruction from '../../underconstruction'

function Main(props) {
    const { page} = props;

  useEffect(() => {
    document.getElementById("title").innerHTML=`Page ${page} en cours de construction`;
  }, [])

  return (
    <>
        <Header/>

        <div className="page-wrapper">
   
       
          < Underconstruction message={  `Désolé,la page ${page} est en cours de construction. elle sera bientot disponible. Merci pour votre intéret et à bienôt!`}/>
        </div>
        
    </>
  )
}

export default Main