import React from 'react'
import Header from '../../headerone/Main'

import VoidList from '../../voidList'




function VoidPage() {

    return (
        <>
            <Header />
            <VoidList message="Désolé la page que vous recherchez n'existe pas!!" />
        </>
    )
}

export default VoidPage