import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllNews } from '../../api/api';
import apiConstant from "../../assets/constants/strapiBaseUrl"
import {
    BlockListSkeleton
} from "../../components/skeletonLoaders";
import VoidList from '../voidList';


function Blog() {
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getAllNews()
            .then((res) => {
                if (res.data?.data.length > 0) {
                    setNewsList(res.data.data.sort((a, b) => new Date(b.attributes.newsDate) - new Date(a.attributes.newsDate)));
                }
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        <>
            <section className="blog-section">
                <div className="container">
                    {loading && !error && <BlockListSkeleton />}

                    {!loading && !error && newsList && newsList.length > 0 && <div>
                        <div className="blog-box">
                            <div className="section-title-box text-center">
                                <div className="section-tagline">Notre Actualité</div>
                                <h2 className="section-title">Consultez nos <br /> derniers  articles </h2>
                            </div>
                        </div>

                        <div className="row row-gutter-y-155 ">
                            {newsList && newsList.length > 0 &&
                                newsList.slice(0, 3).map((item, key) => (
                                    <div className="col-lg-4 pb-5 mb-5">

                                        <div className="blog-card">
                                            <div className="blog-card-image">
                                                {item.attributes.newsListImage && <img src={`${apiConstant}${item.attributes.newsListImage.data?.attributes.formats.small.url}`} className="img-fluid" alt="img-184" />}
                                                {item.attributes.uuid && <Link to={`/newsdetails/${item.attributes.uuid}`} />}
                                            </div>

                                            <div className="blog-card-date">
                                                {item.attributes.uuid && item.attributes.newsDate && <Link to={`/newsdetails/${item.attributes.uuid}`} >{`${new Date(item.attributes.newsDate).toLocaleDateString('fr-FR', {

                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                })}`}</Link>}
                                            </div>

                                            <div className="blog-card-content">
                                                <div className="blog-card-meta">

                                                    {item.attributes.createdBy && <span className="author">
                                                        <Link to="#"> {`${item.attributes.createdBy?.lastname} ${item.attributes.createdBy?.firstname}`}</Link>
                                                    </span>}

                                                </div>

                                                <h4>
                                                    {item.attributes.uuid && <Link to={`/newsdetails/${item.attributes.uuid}`} >
                                                        {item.attributes.newstitle}
                                                    </Link>}
                                                </h4>
                                            </div>

                                        </div>

                                    </div>
                                ))}




                        </div>
                    </div>}
                    {!loading && !error && (!newsList || newsList.length === 0) && <VoidList
                        message={"Désolé, Aucune Actualité n'est disponible. Merci de réessayer plus tard"}
                    />}
                    {error && <VoidList
                        message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
                    />}

                </div>
            </section>

        </>
    )
}

export default Blog