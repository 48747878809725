import React, { useState } from 'react';
import Causes from '../pages/projects/Main'

function Tabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const {tabs}=props;



  // Tableau d'objets pour définir les onglets et leur contenu


  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs-container my-3 my-md-5">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <button key={index} className={activeTab === index ? "active" : ""} onClick={() => handleTabClick(index)}>
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTab].content}
      </div>
    </div>
  );
}

export default Tabs;
