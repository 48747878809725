import React, { useEffect,useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Main from '../meneventsdeatils/Main'
import VoidList from '../../voidList';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { getAllEvents } from '../../../api/api';
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import {
  ResultCardSkeleton
} from "../../../components/skeletonLoaders";


function EVentDetails() {
  const { uuid } = useParams();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllEvents()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setEvent(res.data.data.find(n => n.attributes.uuid === uuid));
        }
      })
      .catch((error) => { setError(error); })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  if (event) {

    document.getElementById("title").innerHTML = `A propos de l'évènement  || ${event.attributes?.eventTitle.toString().replace(/\s/g, '-')}`;
  }


  return (
    <>
      <Header />

      <div className="page-wrapper">
        {event && <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/events"
          secondPage="Nos évènements"
          thirdPage="A propos"
        /*  titledown={news.attributes?.newstitle.toString()} */

        />}

        {loading && !error && <ResultCardSkeleton />}
        {!loading && !error && Object.keys(event).length > 0 &&
          <Main mainDetailImage={`${apiConstant}${event.attributes?.mainDetailImage.data.attributes.url}`}
            eventLocation={event.attributes?.eventLocation}
            eventDate={event.attributes?.eventDate}
            eventTitle={event.attributes?.eventTitle}
            eventContent={event.attributes?.eventContent}
            authorName={`${event.attributes?.createdBy.lastname} ${event.attributes?.createdBy.firstname}`}
            galleryImages={event.attributes?.galleryImages.data}
          />}
        {!loading && !error && Object.keys(event).length === 0 && <VoidList
          message={"Désolé, Cet evènement n'est pas disponible. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
      </div>
    </>
  )
}

export default EVentDetails