import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// Mui Components
import Skeleton from "@mui/material/Skeleton";

/* search result loading skeleton */
export function ResultCardSkeleton() {
  return (
    <div className="p-3 mb-4 rounded-13px bg-white">
      <div className=" d-flex justify-content-center align-items-center" >
       
        <div className=" mb-3">
          <Skeleton variant="rect" width={1000} height={350} className="mb-5"/>
          <Skeleton variant="rect" width={1000} height={250} />
        </div>
        
      </div>
     
    </div>
  );
}

export function DoctorProfileCardSkeleton() {
  return (
    <div className="p-3 mb-4 rounded-13px bg-white">
      <Skeleton variant="rect" width={"100%"} height={300} />
      <Skeleton variant="text" className={"mt-4"} />
      <Skeleton variant="text" width={"70%"} />
      <Skeleton variant="rect" height={50} className={"mt-4"} />
    </div>
  );
}

/* search result list loading skeleton  */
export function ResultSkeletonList() {
  let cardSkeletons = [];
  let randomCount = Math.floor(Math.random() * 5) + 5; // random (4 to 9);
  // create card-skeletons
  for (let index = 0; index < randomCount; index++) {
    cardSkeletons.push(<ResultCardSkeleton key={index} />);
  }
  return (
    <>
      {/* filter-skeleton */}
      <Skeleton className={"mb-4"} variant="rect" width={"100%"} height={28} />
      {/* cards-skeletons */}
      {cardSkeletons}
    </>
  );
}

/* Search page result-filter loading skeleton */
export function ResultFilterSkeleton({ className = "" }) {
  return (
    <Skeleton
      className={className}
      variant="rect"
      width={"100%"}
      height={150}
    />
  );
}

/* User profile loader */
export function ProfileLoader({ className = "" }) {
  return (
    <div className={`${className}`}>
      <Skeleton
        width={"55%"}
        className={"mx-auto"}
        height={18}
        variant="text"
      />
      <Skeleton
        width={"60%"}
        className={"mx-auto"}
        height={16}
        variant="text"
      />
      <Skeleton
        width={"40%"}
        className={"mx-auto mt-3 rounded-pill"}
        height={50}
        variant="text"
      />
    </div>
  );
}

/* List loading skeleton */
export function ListSkeleton({ className = "" }) {
  return (
    <div className={`${className}`}>
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
    </div>
  );
}

/* Section Search Skeleton */
export function SectionSearchSkeleton() {
  return (
    <div className="w-100 h-100 p-0 m-0">
      <Skeleton variant="text" height={143} />
    </div>
  );
}

/* Section Search Skeleton */
export function SectionSearchSquareSkeleton() {
  return (
    <div className="mt-3 mb-1    px-1">
      <Skeleton variant="text" height={250} />
    </div>
  );
}

/* block shaped List loading skeleton */
export function BlockListSkeleton() {
  return (
    <div className={"container"}>
      <div className={"row"}>
        <div className="col-12 col-md-4">
          <Skeleton variant="text" height={250} />
        </div>
        <div className="col-12 col-md-4">
          <Skeleton variant="text" height={250} />
        </div>
        <div className="col-12 col-md-4">
          <Skeleton variant="text" height={250} />
        </div>
      </div>
      <div className={"row d-none d-md-flex"}>
        <div className="col-12 col-md-4">
          <Skeleton variant="text" height={250} />
        </div>
        <div className="col-12 col-md-4">
          <Skeleton variant="text" height={250} />
        </div>
        <div className="col-12 col-md-4">
          <Skeleton variant="text" height={250} />
        </div>
      </div>
    </div>
  );
}

export function TabSkeleton() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
            <TableCell align="right">
              <Skeleton variant="text" height={250} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function DataInfoSkeleton() {
  return (
    <div className="d-flex  bg-white p-3 mb-3">
      <Skeleton variant="rect" width={260} height={130} className="me-3" />
      <div className="flex-fill">
        <Skeleton variant="text" width={"90%"} height={15} />
        <Skeleton variant="text" width={"80%"} height={15} />
      </div>
      <div className="flex-fill">
        <Skeleton variant="rect" height={150} />
      </div>
    </div>
  );
}

/* Forms loading skeleton */
export function FormLoader({ className = "" }) {
  return (
    <div className={`${className}`}>
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={50} />
      <Skeleton variant="text" width={"100%"} height={100} />
      <Skeleton variant="text" width={"100%"} height={50} />
    </div>
  );
}

/* Timeslot (availabilities) loading skeleton */
export function TimeSlotsLoader({ className = "" }) {
  return (
    <div className={`${className}`}>
      <div className={"container"}>
        <div className={"mb-3"}>
          <Skeleton variant="text" width={"100%"} height={15} />
          <div className={"row mb-4"}>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
          </div>
        </div>
        <div className={"mb-3"}>
          <Skeleton variant="text" width={"100%"} height={15} />
          <div className={"row"}>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
            <div className={`col`}>
              <Skeleton variant="text" width={"100%"} height={70} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/* files form loading skeleton */
export function FileFormLoader({ className = "" }) {
  return (
    <div className={`${className}`}>
      <Skeleton variant="rect" width={"100%"} height={150} />
      <Skeleton
        className={`mx-auto mt-4 rounded-pill`}
        variant="rect"
        width={"40%"}
        height={40}
      />
    </div>
  );
}
