import React from 'react'


import ReactMarkdown from "react-markdown";
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import { FaMoneyBill } from 'react-icons/fa'
import { MdDateRange } from 'react-icons/md';
import { Hidden } from '@mui/material';


function Main(props) {
    const { mainDetailImage, realisationCompletionDate, realisationTitle, realisationContent, realisationEstimateBudget, galleryImages } = props;


    return (
        <>
            <section className="causes-details-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="causes-details-box-img">
                                {mainDetailImage && <img src={mainDetailImage} className="img-fluid" />}
                            </div>

                            <div className="causes-details-content-box">
                                <div className="causes-details-funding">


                                    <div className="causes-details-funding-list d-flex justify-content-center ">
                                        <div className="causes-details-funding-item">
                                            <div className="date-widget d-flex align-items-center ">

                                                <div className="date-text text-center fw-bold">
                                                    <Hidden mdUp>
                                                        <div>
                                                            {realisationEstimateBudget && (
                                                                <>
                                                                    <FaMoneyBill className="date-icon" />
                                                                    <span className="me-2">{`Total: ${realisationEstimateBudget.toLocaleString('fr-FR', {
                                                                        style: 'currency',
                                                                        currency: 'XAF'
                                                                    })}`}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {realisationCompletionDate && (
                                                                <>
                                                                    <MdDateRange className="date-icon" />
                                                                    <span date-item>{`Fin: ${new Date(realisationCompletionDate).toLocaleDateString('fr-FR', {
                                                                        year: 'numeric',
                                                                        month: 'short',
                                                                    })}`}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Hidden>
                                                    <Hidden mdDown>
                                                        <div>
                                                            {realisationEstimateBudget && (
                                                                <>
                                                                    <FaMoneyBill className="date-icon" />
                                                                    <span className="me-2">{`Montal total dépensé: ${realisationEstimateBudget.toLocaleString('fr-FR', {
                                                                        style: 'currency',
                                                                        currency: 'XAF'
                                                                    })}`}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {realisationCompletionDate && (
                                                                <>
                                                                    <MdDateRange className="date-icon" />
                                                                    <span date-item>{`Date de fin de réalisation: ${new Date(realisationCompletionDate).toLocaleDateString('fr-FR', {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                    })}`}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Hidden>
                                                </div>
                                            </div>
                                        </div>



                                    </div>

                                </div>

                                <div className="causes-details-summary-box">
                                    {realisationTitle && <h4>{realisationTitle}</h4>}
                                    {realisationContent && <ReactMarkdown children={realisationContent} />}
                                </div>


                            </div>
                            {galleryImages && Array.isArray(galleryImages) && (
                                <div className="causes-details-imgbox">
                                    <div className="row row-gutter-y-30">
                                        {galleryImages.map((item, key) => (
                                            <div className="col-lg-6 mt-3 mt-md-5" key={key}>
                                                <div className="causes-details-img">
                                                    <img src={`${apiConstant}${item.attributes.url}`} className="img-fluid" alt={`img-${key}`} />

                                                </div>
                                            </div>
                                        ))}


                                    </div>

                                </div>
                            )}


                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar">


                                {/*   <div className="sidebar-widget sidebar-widget-recent-donations">
                                    <h4>Recent Donations</h4>
                                    <div className="sidebar-recent-donations">
                                        <div className="sidebar-recent-donations-img">
                                            <img src={Deatils02} className="img-fluid" alt="img-130" />
                                        </div>

                                        <div className="sidebar-recent-donations-content">
                                            <span>$20</span>
                                            <div className="sidebar-widget-organizer-name">
                                                <Link to="#">David Marks</Link>
                                                <span>3 hours ago</span>
                                            </div>

                                            <p>God bless you dear</p>
                                        </div>

                                    </div>

                                    <div className="sidebar-recent-donations">
                                        <div className="sidebar-recent-donations-img">
                                            <img src={Deatils03} className="img-fluid" alt="img-131" />
                                        </div>

                                        <div className="sidebar-recent-donations-content">
                                            <span>$60</span>
                                            <div className="sidebar-widget-organizer-name">
                                                <Link to="#">Aleesha Brown </Link>
                                                <span>3 hours</span>
                                            </div>

                                            <p>God bless you dear</p>
                                        </div>

                                    </div>

                                    <div className="sidebar-recent-donations">
                                        <div className="sidebar-recent-donations-img">
                                            <img src={Deatils04} className="img-fluid" alt="img-132" />
                                        </div>

                                        <div className="sidebar-recent-donations-content">
                                            <span>$30</span>
                                            <div className="sidebar-widget-organizer-name">
                                                <Link to="#">Kevin Martin</Link>
                                                <span>8 hours ago</span>
                                            </div>

                                            <p>God bless you dear</p>
                                        </div>

                                    </div>

                                    <div className="sidebar-recent-donations">
                                        <div className="sidebar-recent-donations-img">
                                            <img src={Deatils05} className="img-fluid" alt="img-133" />
                                        </div>

                                        <div className="sidebar-recent-donations-content">
                                            <span>$110</span>
                                            <div className="sidebar-widget-organizer-name">
                                                <Link to="#">Anonymous</Link>
                                                <span>1 day ago</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>  */}

                            </div>

                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}

export default Main