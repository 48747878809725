import React, { useEffect, useState } from 'react';
import RealisationsList  from '../menrealisations/realisationsList';
import VoidList from '../../voidList';
import {
  getAllRealisations
} from "../../../api/api";
import {
  BlockListSkeleton
} from "../../../components/skeletonLoaders";


function ListOfRealisations() {

  const [realisationsList, setRealisationsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllRealisations()
      .then((res) => {
        if (res.data?.data.length > 0) {
            setRealisationsList(res.data.data.sort((a, b) => new Date(b.attributes.realisationCompletionDate) - new Date(a.attributes.realisationCompletionDate)));
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);





  return (
    <>
        {loading && !error && <BlockListSkeleton />}
        {!loading && !error && realisationsList && realisationsList.length > 0 && <RealisationsList realisationsList={realisationsList} />}
        {!loading && !error && (!realisationsList|| realisationsList.length === 0) && <VoidList
          message={"Désolé, Aucune réalisation n'est disponible. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
   

    </>
  )
}

export default ListOfRealisations