import React from 'react'
import { Link } from 'react-router-dom'
import apiConstant from "../../../assets/constants/strapiBaseUrl"


function NewsList(props) {
    const { newsList } = props;
    return (
        <>
            <section className="blog-section blog-section-two">
                <div className="container">
                    <div className="row row-gutter-y-155">
                        {newsList && newsList.length > 0 &&
                            newsList.map((item, key) => (

                                <div className="col-lg-6 col-xl-4  mb-md-5 py-5">

                                    <div className="blog-card">
                                        <div className="blog-card-image">
                                            {item.attributes.newsListImage && <img 
                                                            style={{ objectFit: 'cover' }}  src={`${apiConstant}${item.attributes.newsListImage.data?.attributes.formats.small.url}`} className=" img-fluid" alt="img-184" />}
                                            {item.attributes.uuid && <Link to={`/newsdetails/${item.attributes.uuid}`} />}
                                        </div>

                                        <div className="blog-card-date">
                                            {item.attributes.uuid && item.attributes.newsDate && <Link to={`/newsdetails/${item.attributes.uuid}`} >{`${new Date(item.attributes.newsDate).toLocaleDateString('fr-FR', {

                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                            })}`}</Link>}
                                        </div>

                                        <div className="blog-card-content">
                                            <div className="blog-card-meta">

                                                {item.attributes.createdBy && <span className="author">
                                                    <Link to="#"> {`${item.attributes.createdBy?.lastname} ${item.attributes.createdBy?.firstname}`}</Link>
                                                </span>}

                                            </div>

                                            <h4>
                                                {item.attributes.uuid && <Link to={`/newsdetails/${item.attributes.uuid}`} >
                                                    {item.attributes.newstitle}
                                                </Link>}
                                            </h4>
                                        </div>

                                    </div>

                                </div>))}



                    </div>

                </div>
            </section>
        </>
    )
}

export default NewsList