import React, { useEffect, useState } from 'react';
import Header from '../../headerone/Main';
import Bredcom from '../../Bredcom/Main';
import DestinationsList from '../mendestinations/destinationsList';
import VoidList from '../../voidList';
import { Link } from 'react-router-dom'
import {
  getAllDestinations
} from "../../../api/api";
import {
  BlockListSkeleton
} from "../../../components/skeletonLoaders";


function Destinations() {

  const [destinationsList, setDestinationsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllDestinations()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setDestinationsList(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  document.getElementById("title").innerHTML = "Espaces touristiques";


  return (
    <>
      <Header />

      <div className="page-wrapper">
        <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/destinations"
          secondPage="Espaces touristiques"
          titledown="Espaces touristiques"
        />
        {loading && !error && <BlockListSkeleton />}
        {!loading && !error && destinationsList && destinationsList.length > 0 && <DestinationsList destinationsList={destinationsList} />}
        {!loading && !error && (!destinationsList|| destinationsList.length === 0) && <VoidList
          message={"Désolé, Pas d'espace touristique pou le moment . Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
      </div>

    </>
  )
}

export default Destinations