import React from 'react'
import { Link } from 'react-router-dom'
import apiConstant from "../../../assets/constants/strapiBaseUrl"

function EventList(props) {
    const { eventsList } = props;
    const truncateTitle = (title, maxLength = 100) => {
        if (title.length <= maxLength) {
            return title;
        } else {
            // Couper la chaîne et ajouter "..." à la fin
            return title.slice(0, maxLength) + "...";
        }
    };

    return (
        <>
            <section className="event-three-section">
                <div className="event-section-outer">
                    <div className="container">
                        <div className="row row-gutter-y-30">

                            {eventsList && eventsList.length > 0 &&
                                eventsList.map((item, key) => (
                                    <div className="col-12 col-md-6">
                                        <div className="event-card">
                                            <div className="event-card-image">

                                                <div className="event-card-image-inner">
                                                    {item.attributes.uuid && <Link to={`/eventdetails/${item.attributes.uuid}`} >
                                                        {item.attributes.eventListImage && <img width="300"
                                                            height="300"
                                                            style={{ objectFit: 'cover' }} src={`${apiConstant}${item.attributes.eventListImage.data?.attributes.formats.small.url}`} className="img-fluid" alt="img-184" />}
                                                    </Link>}
                                                    <div className="event-card-meta">
                                                        <div className="event-meta-number">
                                                            {item.attributes.eventDate && <span>{`${new Date(item.attributes.eventDate).toLocaleDateString('fr-FR', {
                                                                day: 'numeric',
                                                            })}`}</span>}
                                                        </div>
                                                        <div className="event-meta-date">
                                                            {item.attributes.eventDate && <span>{`${new Date(item.attributes.eventDate).toLocaleDateString('fr-FR', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                            })}`}</span>}
                                                        </div>
                                                    </div>



                                                </div>

                                            </div>

                                            <div className="event-card-content">
                                                <div className="event-card-info">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <i className="fa-solid fa-clock" />
                                                            {item.attributes.eventDate && <span> {`${new Date(item.attributes.eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}</span>}
                                                        </li>

                                                        <li>
                                                            <i className="fa-sharp fa-solid fa-location-pin" />
                                                            {item.attributes.eventLocation && <span> {item.attributes.eventLocation}</span>}
                                                        </li>

                                                    </ul>

                                                </div>

                                                <div className="event-card-title">
                                                    <h4>
                                                        {item.attributes.uuid && <Link to={`/eventdetails/${item.attributes.uuid}`} >
                                                            {item.attributes.eventTitle && <span>{truncateTitle(item.attributes.eventTitle)}</span>}
                                                        </Link>}
                                                    </h4>
                                                </div>

                                            </div>

                                        </div>


                                    </div>


                                ))}

                        </div>

                    </div>

                </div>
            </section >
        </>
    )
}

export default EventList