import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/image/adgf_logo.png';
import Light_Logo from '../../assets/image/logo_of_adgf.png';

function Main() {
  const [Btnshow, setBtnshow] = useState(false);
  const [search, setsearch] = useState(false);
  const [fomopea, setFomopea] = useState(false);
  const [adgf, setAdgf] = useState(false);
  const [News, setNews] = useState(false);
  const [Departments, setDepartments] = useState(false);
  const [Events, setEvents] = useState(false);

  return (
    <>
      <header className="header">
        <div className="topbar">
          <div className="topbar-inner">
            <div className="topbar-left">
              <div className="topbar-socials">
                <a href="#" target="_blank">
                  <i className="fa-brands fa-facebook" />
                </a>
                <a href="https://www.youtube.com/@wemaProd" target="_blank">
                  <i className="fa-brands fa-youtube" />
                </a>
              </div>

              <div className="topbar-info">
                <ul>
                  <li>
                    <div className="topbar-icon">
                      <i className="fa-solid fa-envelope" />
                    </div>

                    <div className="topbar-text">
                      <span>info@adgfomopea.org</span>
                    </div>
                  </li>

                  <li>
                    <div className="topbar-icon">
                      <i className="fa-solid fa-phone" />
                    </div>

                    <div className="topbar-text">
                      <span>+237 6 71 44 31 17</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="main-menu sticky-header">
          <div className="main-menu-inner">
            <div className="main-menu-left d-flex align-items-center">
              <div className="main-menu-logo">
                <Link to="/">
                  <img src={Logo} alt="img-1" width={140} />
                </Link>
              </div>
              <div className="navigation">
                <ul className="main-menu-list list-unstyled">
                  <li className="list-unstyled">
                    <Link to="/">Accueil</Link>
                  </li>
                  <li className="has-dropdown">
                    <Link to="#">FOMOPEA</Link>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/fomopea-history">Notre histoire</Link>
                      </li>
                      <li>
                        <Link to="/fomopea-dynasty">La dynastie royale</Link>
                      </li>
                      <li>
                        <Link to="/chieftaincy">La chefferie et les notables</Link>
                      </li>
                      <li>
                        <Link to="/neighborhoods">les différents quartiers</Link>
                      </li>
                      <li>
                        <Link to="/associations">La vie associative</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="has-dropdown">
                    <Link to="#">L'A.D.G.F</Link>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/about-adgf">A propos de nous</Link>
                      </li>
                      <li>
                        <Link to="/commissions-adgf">Les différentes commissions</Link>
                      </li>
                      <li>
                        <Link to="/realisations-and-projects">Réalisations et projets</Link>
                      </li>
                    </ul>
                  </li>

                  {/*  <li className="has-dropdown">
                    <Link to="#">Departments</Link>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/departments">Departments</Link>
                      </li>
                      <li>
                        <Link to="/departmentdetails">Department Details</Link>
                      </li>
                    </ul>
                  </li> */}

                  <li>
                    <Link to="/events">Les évènements</Link>
                  </li>

                  <li>
                    <Link to="/news">Notre Blog</Link>
                  </li>
                  <li>
                    <Link to="/destinations">Destinations touristiques</Link>
                  </li>

                  <li>
                    <Link to="/contact-us"> Nous Contacter</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="main-menu-right">
              <div
                className="mobile-menu-button mobile-nav-toggler"
                onClick={() => {
                  setBtnshow(true);
                }}
              >
                <span />
                <span />
                <span />
              </div>

              <div className="search-box"></div>

              <div className="custom-main-menu-right-button">
                <Link to="/contribute" className="btn btn-primary">
                  <i className="fa-solid fa-white fa-money-bill-transfer" />
                  Contribuer
                </Link>
              </div>

            </div>
          </div>
        </div>
      </header>

      {Btnshow && (
        <div className="mobile-nav-wrapper expanded">
          <div className="mobile-nav-overlay mobile-nav-toggler" />
          <div className="mobile-nav-content">
            <a
              href="#"
              className="mobile-nav-close mobile-nav-toggler"
              onClick={() => setBtnshow(false)}
            >
              <span />
              <span />
            </a>

            <div className=" d-flex flex-column">
              <Link className="col-12" to="/">
                <img src={Light_Logo} width={150} />
              </Link>
            </div>

            <div className="mobile-nav-container">
              <ul className="main-menu-list list-unstyled">
                <li className="active has-dropdown">
                  <Link to="#">Accueil</Link>
                </li>

                <li className="has-dropdown">
                  <Link to="#">
                    FOMOPEA
                    <button className={fomopea ? 'expanded' : ''}>
                      <i
                        className="fa-solid fa-chevron-right"
                        onClick={() => {
                          setFomopea(!fomopea);
                        }}
                      />
                    </button>
                  </Link>
                  {fomopea && (
                    <ul className="list-unstyled" style={{ display: 'block' }}>
                      <li>
                        <Link to="/fomopea-history">Notre Histoire</Link>
                      </li>
                      <li>
                        <Link to="/fomopea-dynasty">La dynastie Royale</Link>
                      </li>
                      <li>
                        <Link to="/chieftaincy">La chefferie et les notables</Link>
                      </li>
                      <li>
                        <Link to="/neighborhoods">Les différents quartiers</Link>
                      </li>
                      <li>
                        <Link to="/associations">La vie Associative</Link>
                      </li>
                    </ul>
                  )}
                </li>

                <li className="has-dropdown">
                  <Link to="#">
                    L'A.D.G.F
                    <button className={adgf ? 'expanded' : ''}>
                      <i
                        className="fa-solid fa-chevron-right"
                        onClick={() => {
                          setAdgf(!adgf);
                        }}
                      />
                    </button>
                  </Link>

                  {adgf && (
                    <ul className="list-unstyled" style={{ display: 'block' }}>
                      <li>
                        <Link to="/about-adgf">A propos de nous</Link>
                      </li>
                      <li>
                        <Link to="/commissions-adgf">Les différentes commissions</Link>
                      </li>
                      <li>
                        <Link to="/realisations-and-projects">Réalisations et projets</Link>
                      </li>
                    </ul>
                  )}
                </li>

                <li>
                  <Link to="/events">Les évènements</Link>
                </li>

                <li>
                  <Link to="/news">Notre Blog</Link>
                </li>

                <li>
                  <Link to="/destinations">Destinations touristiques</Link>
                </li>

                <li>
                  <Link to="/contact-us"> Nous Contacter</Link>
                </li>
                <li className="d-flex align-items-center text-white fw-bold" >
                  <i className="fa-solid fa-white fa-money-bill-transfer" />
                  <Link to="/contribute"> Contribuer</Link>
                </li>
              </ul>


              <div className="main-menu-right-button"></div>
            </div>

            <ul className="mobile-nav-contact list-unstyled">
              <li>
                <i className="fa-solid fa-phone" />
                +237 6 71 44 31 17
              </li>

              <li>
                <i className="fa-solid fa-envelope" />
                <a>info@adgfomopea.org</a>
              </li>
            </ul>

            <ul className="mobile-nav-social list-unstyled">

              <li>
                <a href="">
                  <i className="fa-brands fa-facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@wemaProd" target="_blank">
                  <i className="fa-brands fa-youtube" />
                </a>
              </li>

            </ul>
          </div>
        </div >
      )
      }

      <a href="#" className="scroll-to-top scroll-to-target" data-target="html">
        <i className="fa-solid fa-arrow-up" />
      </a>
    </>
  );
}

export default Main;
