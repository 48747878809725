import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apiConstant from "../../../assets/constants/strapiBaseUrl";
import ReactMarkdown from "react-markdown";

function App(props) {
    const { name, content, indication, galleryImages } = props;
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [thumbsToShow, setThumbsToShow] = useState(5)

    const settingsMain = {
        adaptiveHeight: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    };

    const settingsThumbs = {
        slidesToShow: thumbsToShow,

        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '0px',
        arrows: false,

    };

    useEffect(() => {
        const handleResize = () => {
            // Mettre à jour le nombre de miniatures affichées en fonction de la largeur de l'écran
            if (window.innerWidth <= 480) {
                setThumbsToShow(3); // Pour les écrans de 480px ou moins, afficher 3 miniatures
            } else {
                setThumbsToShow(5); // Sinon, afficher 5 miniatures
            }
        };
        handleResize(); // Appeler la fonction de redimensionnement une fois au chargement de la page


        window.addEventListener('resize', handleResize); // Écouter les événements de redimensionnement de la fenêtre

        // Nettoyage de l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>  <section className="news-details-section">
            <div className="container">

                <div className="news-details-content-box">
                    <div className="title-section">
                        {name && <h1>{name}</h1>}
                        {indication && <h3>{indication}</h3>}
                    </div>

                </div>

                <div className="ps-0 news-details-box-image">
                    {galleryImages && Array.isArray(galleryImages) && (
                        <>
                            <Slider className="slider-main" {...settingsMain} asNavFor={nav2} ref={slider => setNav1(slider)}>
                                {galleryImages.map((slide, index) => (
                                    <div className="d-flex justify-content-center slick-slide zoomable-image" key={slide.id}>
                                        <img className={window.innerWidth <= 767 ? "slick-slide-image mobile-main-image" : "slick-slide-image"} src={`${apiConstant}${slide.attributes.formats.large.url}`} alt={slide.title} />
                                    </div>
                                ))}
                            </Slider>
                            <div className="thumbnail-slider-wrap">
                                <Slider className="slider-thumbs" {...settingsThumbs} asNavFor={nav1} ref={slider => setNav2(slider)}>
                                    {galleryImages.map((slide) => (
                                        <div className="slick-slide" key={slide.id}>
                                            <img className="slick-slide-image thumbnail-image" src={`${apiConstant}${slide.attributes.formats.large.url}`} alt={slide.title} />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </>
                    )}

                </div>


                <div className="news-details-content-box">
                    {content && <ReactMarkdown children={content} />}

                </div>

            </div>


        </section>
        </>
    );
}

export default App;
