import React from 'react';
import Gallery01 from '../../assets/image/gallery/mont-neyang1.jpg';
import Gallery02 from '../../assets/image/gallery/about-2.jpg';
import gallery04 from '../../assets/image/gallery/ak.jpeg';
import Shapes03 from '../../assets/image/shapes/mont-neyang.jpg';

function Intro() {
  return (
    <>
      <section className="about-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="about-image">
                <div className="about-image-inner img-one">
                  <img src={Gallery01} className="img-fluid" alt="img-2" />
                  <div className="sign-text">L'A.D.G.F</div>

                  <div className="about-image-caption">
                    <div className="about-image-caption-inner">
                      <span className="about-caption-text">
                        Un paysage <br /> très riche
                      </span>
                    </div>
                  </div>
                </div>

                <div className="about-image-inner img-two">
                  <img src={Shapes03} className="img-fluid" alt="img-4" />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-inner">
                <div className="section-title-box">
                  <div className="section-tagline">Notre mot de Bienvenue</div>

                  <h2 className="section-title">FOMOPEA</h2>
                  <p>
                    Nous vous souhaitons un excellent moment sur le site web du
                    groupement FOMOPEA. N'hésitez sourtout pas à parcourir
                    toutes les rubriques. Vous pouvez également participer au
                    développement du goupement en:
                  </p>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div className="about-card">
                      <h4 className="about-title">
                        <i className="fa-solid fa-circle-check" />
                        L'A.D.G.F
                      </h4>
                      <p className="about-text">
                        En travaillant en bénévolat pour une commission de
                        l'A.D.G.F
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-6">
                    <div className="about-card">
                      <h4 className="about-title">
                        <i className="fa-solid fa-circle-check" />
                        Apport financier
                      </h4>
                      <p className="about-text">
                        En contribuant financièrement pour l'un des projets en
                        cours
                      </p>
                    </div>
                  </div>
                </div>

                <div className="about-author-box">
                  <div className="about-author-image">
                    <img src={gallery04} className="img-fluid" alt="img-5" />
                  </div>

                  <div className="about-author-box-meta">
                    <h5>M. KAMANOU Albert</h5>
                    <span>Président de l'A.D.G.F</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Intro;
