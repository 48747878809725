import { getRequest } from './httpClient';


export const getAllNews = () => {
  return getRequest(`articles?populate=*`);
};


export const getAllEvents = () => {
  return getRequest(`events?populate=*`);
};

export const getAllDestinations= () => {
  return getRequest(`destinations?populate=*`);
};


export const getAllProjects= () => {
  return getRequest(`projects?populate=*`);
};


export const getAllRealisations= () => {
  return getRequest(`realisations?populate=*`);
};
