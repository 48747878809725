import React, { useEffect } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import First from '../mencauses/First'
import Tabs  from '../../tabs/Tabs'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML = "Causes || TownGov";
  }, [])

  const tabs = [
    { title: 'REALISALIONS', content: <First  /> },
    { title: 'PROJETS', content: <p>Contenu du Tab 2</p> },
  ];


  return (
    <>
      <Header />

      <div className="page-wrapper">
        <Bredcom
          subtitle="Home"
          title="causes"
          subtitledown="Causes"
        />
        <Tabs tabs={tabs}/>
      </div>
    </>
  )
}

export default Main