import React, { useEffect, useState } from 'react';
import Header from '../../headerone/Main';
import Bredcom from '../../Bredcom/Main';
import NewsList from '../mennews/NewsList';
import VoidList from '../../voidList';
import {
  getAllNews
} from "../../../api/api";
import {
  BlockListSkeleton
} from "../../../components/skeletonLoaders";

function ListOfNews() {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllNews()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setNewsList(res.data.data.sort((a, b) => new Date(b.attributes.newsDate) - new Date(a.attributes.newsDate)));
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  document.getElementById("title").innerHTML = "Blog ";

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/news"
          secondPage="Blog"
          titledown="Notre Blog"
        />
        {loading && !error && <BlockListSkeleton />}
        {!loading && !error && newsList && newsList.length > 0 && <NewsList newsList={newsList} />}
        {!loading && !error && (!newsList || newsList.length === 0) && <VoidList
          message={"Désolé, Aucune Actualité n'est disponible. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
      </div>
    </>
  );
}

export default ListOfNews;
