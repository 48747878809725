import React from 'react'
import { Link } from 'react-router-dom'
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import { FaMoneyBill } from 'react-icons/fa'
import { MdDateRange } from 'react-icons/md';



function RealisationsList(props) {
    const { realisationsList } = props;
    const truncateTitle = (title, maxLength) => {
        if (title.length <= maxLength) {
            return title;
        } else {
            // Couper la chaîne et ajouter "..." à la fin
            return title.slice(0, maxLength) + "...";
        }
    };

    return (
        <>
            <section className="causes-section">
                <div className="container">
                    <div className="row row-gutter-30">
                        {realisationsList && realisationsList.length > 0 &&
                            realisationsList.map((item, key) => (

                                <div className="col-lg-6 col-xl-4">
                                    <div className="causes-card">
                                        <div className="causes-card-imgbox">
                                            {item.attributes.realisationListImage && <img width="555"
                                                height="426"
                                                style={{ objectFit: 'cover' }} src={`${apiConstant}${item.attributes.realisationListImage.data?.attributes.formats.small.url}`} className="img-fluid" alt="img-184" />}
                                        </div>

                                        <div className="causes-content">
                                            <div className="causes-card-funding">


                                                <div className="causes-details-funding-list d-flex justify-content-center ">
                                                    <div className="causes-details-funding-item">
                                                        <div className="date-widget d-flex align-items-center ">

                                                            <div className="date-text text-center fw-bold">
                                                                {item.attributes.realisationEstimateBudget && <> <FaMoneyBill className="date-icon" /><span className="me-2">{`${item.attributes.realisationEstimateBudget.toLocaleString('fr-FR', {
                                                                    style: 'currency',
                                                                    currency: 'XAF'
                                                                })}`}</span></>}
                                                                {item.attributes.realisationCompletionDate && <> <MdDateRange  className="date-icon" /><span date-item>{`${new Date(item.attributes.realisationCompletionDate).toLocaleDateString('fr-FR', {
                                                                    year: 'numeric',
                                                                    month: 'short',
                                                                })}`}</span></>}
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>

                                            </div>
                                            <h4>
                                                {item.attributes.uuid && <Link to={`/realisationdetails/${item.attributes.uuid}`} >
                                                    {item.attributes.realisationTitle && <span>{truncateTitle(item.attributes.realisationTitle,50)}</span>}
                                                </Link>}
                                            </h4>

                                            {item.attributes.uuid && <Link to={`/realisationdetails/${item.attributes.uuid}`}className="btn btn-primary ">
                                                <i className="fa-solid fa-white fa-eye" />
                                                Voir la réalisation en détail
                                            </Link>}


                                        </div>

                                    </div>

                                </div>

                            ))}

                    </div>

                </div>
            </section>
        </>
    )
}

export default RealisationsList