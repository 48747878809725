import React, { useEffect } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import RealisationsList from './realisationsList'
import  ListOfProjects from '../projectsList/projectsList'
import Tabs  from '../../tabs/Tabs'


function Main() {

  useEffect(() => {
    document.getElementById("title").innerHTML = "Réalisations et Projets";
  }, [])

  const tabs = [
    { title: 'REALISALIONS', content: <RealisationsList  /> },
    { title: 'PROJETS', content: <ListOfProjects   /> },
  ];


  return (
    <>
      <Header />

      <div className="page-wrapper">
      <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/realisations-and-projects"
          secondPage="Réalisations et projets"
          titledown="Réalisations et projets"
        />
        <Tabs tabs={tabs}/>
      </div>
    </>
  )
}

export default Main