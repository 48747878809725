import React from 'react'
import { FaCalendarAlt } from 'react-icons/fa'; // Import de l'icône de calendrier depuis react-icons

import { Link } from 'react-router-dom'
import Cause01 from '../../../assets/image/causes/causes-1.jpg'
import Cause02 from '../../../assets/image/causes/causes-2.jpg'
import Cause03 from '../../../assets/image/causes/causes-3.jpg'
import Cause04 from '../../../assets/image/causes/causes-4.jpg'
import Cause05 from '../../../assets/image/causes/causes-5.jpg'
import Cause06 from '../../../assets/image/causes/causes-6.jpg'


function First() {
    // Obtenir la date actuelle
    const currentDate = new Date();
    // Obtenir le jour, le mois et l'année
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    return (
        <>
            <section className="causes-section">
                <div className="container">
                    <div className="row row-gutter-30">
                        <div className="col-lg-6 col-xl-4">
                            <div className="causes-card">
                                <div className="causes-card-imgbox">
                                    <img src={Cause01} className="img-fluid" alt="img-117" />
                                </div>

                                <div className="causes-content">
                                    <div className="causes-card-funding">


                                        <div className="causes-details-funding-list d-flex justify-content-center ">
                                            <div className="causes-details-funding-item">
                                                <div className="date-widget d-flex align-items-center ">
                                                    <FaCalendarAlt className="date-icon" /> {/* Icône de calendrier */}
                                                    <div className="date-text text-center">
                                                        <span className="date-item me-5">{month}</span> {/* Affichage du mois */}
                                                        <span className="date-item">{year}</span> {/* Affichage de l'année */}
                                                    </div>
                                                </div>
                                            </div>



                                        </div>

                                    </div>

                                    <h4>
                                        {" "}
                                        <Link to="/causedetails">
                                            Your little help can heal their pains
                                        </Link>{" "}
                                    </h4>
                                    <p>
                                        Aellentesque porttitor lacus quis enim varius sed efficitur...
                                    </p>
                                    <Link to="/causedetails" className="btn btn-primary">
                                        Donate Now
                                    </Link>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-6 col-xl-4">
                            <div className="causes-card">
                                <div className="causes-card-imgbox">
                                    <img src={Cause02} className="img-fluid" alt="img-118" />
                                </div>

                                <div className="causes-content">
                                    <div className="causes-card-funding">
                                        <div className="causes-card-progressbar">
                                            <div className="causes-card-progressbar-inner count-box" data-percentage="36%" >
                                                <div className="causes-card-progressbar-text count-text" />

                                            </div>

                                        </div>

                                        <div className="causes-card-funding-list">
                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$25,487</div>

                                                <div className="causes-card-funding-text">Raised</div>

                                            </div>

                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$30,000</div>

                                                <div className="causes-card-funding-text">Goal</div>

                                            </div>

                                        </div>

                                    </div>

                                    <h4>
                                        {" "}
                                        <Link to="/causedetails">
                                            Your little help can heal their pains
                                        </Link>{" "}
                                    </h4>
                                    <p>
                                        Aellentesque porttitor lacus quis enim varius sed efficitur...
                                    </p>
                                    <Link to="/causedetails" className="btn btn-primary">
                                        Donate Now
                                    </Link>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-6 col-xl-4">
                            <div className="causes-card">
                                <div className="causes-card-imgbox">
                                    <img src={Cause03} className="img-fluid" alt="img-119" />
                                </div>

                                <div className="causes-content">
                                    <div className="causes-card-funding">
                                        <div className="causes-card-progressbar">
                                            <div
                                                className="causes-card-progressbar-inner count-box"
                                                data-percentage="36%"
                                            >
                                                <div className="causes-card-progressbar-text count-text" />

                                            </div>

                                        </div>

                                        <div className="causes-card-funding-list">
                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$25,487</div>

                                                <div className="causes-card-funding-text">Raised</div>

                                            </div>

                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$30,000</div>

                                                <div className="causes-card-funding-text">Goal</div>

                                            </div>

                                        </div>

                                    </div>

                                    <h4>
                                        {" "}
                                        <Link to="/causedetails">
                                            Your little help can heal their pains
                                        </Link>{" "}
                                    </h4>
                                    <p>
                                        Aellentesque porttitor lacus quis enim varius sed efficitur...
                                    </p>
                                    <Link to="/causedetails" className="btn btn-primary">
                                        Donate Now
                                    </Link>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-6 col-xl-4">
                            <div className="causes-card">
                                <div className="causes-card-imgbox">
                                    <img src={Cause04} className="img-fluid" alt="img-120" />
                                </div>

                                <div className="causes-content">
                                    <div className="causes-card-funding">
                                        <div className="causes-card-progressbar">
                                            <div
                                                className="causes-card-progressbar-inner count-box"
                                                data-percentage="36%"
                                            >
                                                <div className="causes-card-progressbar-text count-text" />

                                            </div>

                                        </div>

                                        <div className="causes-card-funding-list">
                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$25,487</div>

                                                <div className="causes-card-funding-text">Raised</div>

                                            </div>

                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$30,000</div>

                                                <div className="causes-card-funding-text">Goal</div>

                                            </div>

                                        </div>

                                    </div>

                                    <h4>
                                        {" "}
                                        <Link to="/causedetails">
                                            Your little help can heal their pains
                                        </Link>{" "}
                                    </h4>
                                    <p>
                                        Aellentesque porttitor lacus quis enim varius sed efficitur...
                                    </p>
                                    <Link to="/causedetails" className="btn btn-primary">
                                        Donate Now
                                    </Link>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-6 col-xl-4">
                            <div className="causes-card">
                                <div className="causes-card-imgbox">
                                    <img src={Cause05} className="img-fluid" alt="img-121" />
                                </div>

                                <div className="causes-content">
                                    <div className="causes-card-funding">
                                        <div className="causes-card-progressbar">
                                            <div
                                                className="causes-card-progressbar-inner count-box"
                                                data-percentage="36%"
                                            >
                                                <div className="causes-card-progressbar-text count-text" />

                                            </div>

                                        </div>

                                        <div className="causes-card-funding-list">
                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$25,487</div>

                                                <div className="causes-card-funding-text">Raised</div>

                                            </div>

                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$30,000</div>

                                                <div className="causes-card-funding-text">Goal</div>

                                            </div>

                                        </div>

                                    </div>

                                    <h4>
                                        {" "}
                                        <Link to="/causedetails">
                                            Your little help can heal their pains
                                        </Link>
                                    </h4>
                                    <p>
                                        Aellentesque porttitor lacus quis enim varius sed efficitur...
                                    </p>
                                    <Link to="/causedetails" className="btn btn-primary">
                                        Donate Now
                                    </Link>
                                </div>

                            </div>

                        </div>

                        <div className=" col-lg-6 col-xl-4">
                            <div className="causes-card">
                                <div className="causes-card-imgbox">
                                    <img src={Cause06} className="img-fluid" alt="img-122" />
                                </div>

                                <div className="causes-content">
                                    <div className="causes-card-funding">
                                        <div className="causes-card-progressbar">
                                            <div className="causes-card-progressbar-inner count-box" data-percentage="36%" >
                                                <div className="causes-card-progressbar-text count-text" />

                                            </div>

                                        </div>

                                        <div className="causes-card-funding-list">
                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$25,487</div>

                                                <div className="causes-card-funding-text">Raised</div>

                                            </div>

                                            <div className="causes-card-funding-item">
                                                <div className="causes-card-funding-amount">$30,000</div>

                                                <div className="causes-card-funding-text">Goal</div>

                                            </div>

                                        </div>

                                    </div>

                                    <h4>
                                        {" "}
                                        <Link to="/causedetails">
                                            Your little help can heal their pains
                                        </Link>{" "}
                                    </h4>
                                    <p>
                                        Aellentesque porttitor lacus quis enim varius sed efficitur...
                                    </p>
                                    <Link to="/causedetails" className="btn btn-primary">
                                        Donate Now
                                    </Link>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default First