import React from 'react'
import { Link } from 'react-router-dom'

function Main( props) {
  const {firstPageRoute,firstPage,secondPageRoute,secondPage,thirdPage,titledown}=props;
  return (
    <>
      <section className="page-banner">
        <div className="container">
          <div className="page-breadcrumbs">
            <ul className="list-unstyled">
              <li>
                <Link to={firstPageRoute}> {firstPage} </Link>
              </li>
              {thirdPage ? <><li>
                <Link to={secondPageRoute}> {secondPage} </Link>
              </li>
                <li> {thirdPage} </li></> : <li> {secondPage}</li>}

            </ul>

          </div>

          <div className="page-banner-title">
            <h3> {titledown} </h3>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main