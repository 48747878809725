import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import apiConstant from "../../../assets/constants/strapiBaseUrl"
import { getAllNews } from '../../../api/api';
import moment from 'moment';
import ReactMarkdown from "react-markdown";
import {
    BlockListSkeleton
} from "../../../components/skeletonLoaders";

import VoidList from '../../../components/voidList';


function Main(props) {

    const { mainDetailImage, newsDate, authorName, newstitle, newsContent, newsPublishedDate, galleryImages, uuid, setReloadPage } = props;
    const [newsList, setNewsList] = useState([]);
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const style = {
        base: {
            //color: '#007bff', // Couleur du texte
            fontWeight: 'lighter',
            cursor: 'pointer', // Curseur au survol
            textDecoration: hovered ? 'underline' : 'none', // Ajout d'un soulignement au survol
        },
    };

    const settings = {
        items: 3,
        nav: false,
        loop: true,
        margin: 10,
        autoplay: true,
        smartSpeed: 2000,
        responsive: {
            0: {
                items: 1,
            },
            767: {
                items: 2
            },
            1199: {
                items: 3
            }
        }
    };



    useEffect(() => {
        setLoading(true);
        getAllNews()
            .then((res) => {
                if (res.data?.data.length > 0) {
                    setNewsList(res.data.data.sort((a, b) =>new Date( b.attributes.newsDate) - new Date(a.attributes.newsDate)));
                }
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });

    }, [])


    const handleClick = (path) => (event) => {
        setReloadPage(true);
        event.preventDefault();
        navigate(path);


    };

    return (
        <>
            <section className="news-details-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ps-0 news-details-box-image">
                                <div className="news-details-box-image-inner">
                                    {mainDetailImage && <img height={710} width={414} src={mainDetailImage} className="img-fluid" alt="img-193" />}
                                    {newsDate && <Link className="news-details-box-date" to={`/newsdetails/${uuid}`} >{`${new Date(newsDate).toLocaleDateString('fr-FR', {

                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    })}`}</Link>}
                                </div>

                            </div>

                            <div className="ps-0 news-details-meta-box">
                                <div className="fs-6 fst-italic fw-lighter" style={{ lineHeight: 1.5 }}>
                                    {/*        {newsPublishedDate && authorName && <span className="author text-start "> {`Publié par ${authorName} le ${new Date(Date.parse(newsPublishedDate)).toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' })}`}</span>}
 */}
                                    {`Publié par ${authorName} le ${new Date(moment(newsPublishedDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').locale('fr').format('LLLL')).toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' })} à 
       ${new Date(moment(newsPublishedDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').locale('fr').format('LLLL')).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}

                                </div>

                            </div>

                            <div className="news-details-content-box">
                                {newstitle && <h4>{newstitle}</h4>}
                                {newsContent && <ReactMarkdown children={newsContent} />}
                                {/*   {newsContent && <div dangerouslySetInnerHTML={{ __html: newsContent }} />} */}
                            </div>




                            {galleryImages && Array.isArray(galleryImages) && (
                                <div className="event-details-gallery-box">
                                    <h3>Quelques images illustratives</h3>
                                    <OwlCarousel className="event-details-carousel owl-carousel owl-theme" {...settings}>
                                        {galleryImages.map((item, key) => (
                                            <div className="item" key={key}>
                                                <img src={`${apiConstant}${item.attributes.formats.medium.url}`} className="img-fluid" alt={`img-${key}`} />
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            )}




                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar">


                                <div className="sidebar-widget sidebar-widget-recent-post">
                                    <h4>Articles récents</h4>

                                    {loading && !error && <BlockListSkeleton />}
                                    {!loading && !error && newsList && newsList.length > 0 &&
                                        newsList.filter(item => item.attributes.uuid !== uuid).slice(0, 5).map((item, key) => (

                                            <div className="sidebar-recent-post ">
                                                <div className="sidebar-recent-post-img ">
                                                    <img height={100} width={100} src={`${apiConstant}${item.attributes?.mainDetailImage.data.attributes.formats.small.url}`} alt="img-197" />

                                                </div>

                                                <div className="sidebar-recent-post-content">
                                                    <div className="sidebar-meta">
                                                        <div className="sidebar-meta-item">
                                                            <div className="sidebar-meta-icon">

                                                                {item.attributes.createdBy && <span >
                                                                    <Link to={`/newsdetails/${item.attributes.uuid}`}> {`Par ${item.attributes.createdBy?.lastname} ${item.attributes.createdBy?.firstname}`}</Link>
                                                                </span>}


                                                            </div>

                                                        </div>

                                                        {item.attributes.uuid && <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={style.base} className="sidebar-post-title" onClick={handleClick(`/newsdetails/${item.attributes.uuid}`)}>
                                                            <h5>
                                                                {item.attributes.newstitle}
                                                            </h5>
                                                        </div>}




                                                    </div>

                                                </div>

                                            </div>

                                        ))}
                                    {!loading && !error && (!newsList || newsList.length === 0) && <VoidList
                                        message={"Désolé, Aucune Actualité n'est disponible. Merci de réessayer plus tard"}
                                    />}
                                    {error && <VoidList
                                        message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
                                    />}

                                </div>




                            </div>

                        </div>
                    </div>
                </div>


            </section>
        </>
    )
}

export default Main