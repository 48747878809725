import React, { useEffect, useState } from 'react';
import Header from '../../headerone/Main';
import Bredcom from '../../Bredcom/Main';
import EventList from '../menevents/eventList';
import VoidList from '../../voidList';
import {
  getAllEvents
} from "../../../api/api";
import {
  BlockListSkeleton
} from "../../../components/skeletonLoaders";


function ListOfEVents() {

  const [eventsList, setEventsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllEvents()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setEventsList(res.data.data.sort((a, b) => new Date(b.attributes.eventDate) - new Date(a.attributes.eventDate)));
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  document.getElementById("title").innerHTML = "Nos évènements";


  return (
    <>
      <Header />

      <div className="page-wrapper">
        <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/events"
          secondPage="Evènements"
          titledown="Nos évènements"
        />
        {loading && !error && <BlockListSkeleton />}
        {!loading && !error && eventsList && eventsList.length > 0 && <EventList eventsList={eventsList} />}
        {!loading && !error && (!eventsList|| eventsList.length === 0) && <VoidList
          message={"Désolé, Aucune Actualité n'est disponible. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
      </div>

    </>
  )
}

export default ListOfEVents