import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../assets/image/logo_footer.png'

function Main() {
    return (
        <>
            <section className="footer">
                <div className="footer-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="">
                                    <Link to="/">
                                        <img src={Logo} className="img-fluid" alt="img-25" />
                                    </Link>
                                </div>

                                <div className="footer-widget-text">
                                    <p>
                                        Sa’ché … Néyang !
                                        <p>
                                            Découvrez adgfomopea.org, votre vitrine en ligne pour plonger dans l'univers dynamique de FOMOPEA et ses initiatives connexes.
                                        </p>
                                    </p>
                                </div>

                                <div className="footer-widget-socials">

                                    <a href="#">
                                        <i className="fa-brands fa-facebook" />
                                    </a>
                                    <a href="https://www.youtube.com/@wemaProd" target="_blank" >
                                        <i className="fa-brands fa-youtube" />
                                    </a>

                                </div>

                            </div>

                            <div className="col-lg-3">
                                <div className="footer-widget">
                                    <div className="footer-widget-explore">
                                        <h4 className="footer-widget-title">FOMOPEA</h4>
                                        <ul className="list-unstyled">
                                            <li>
                                                <Link to="/destinations">Destinations touristiques</Link>
                                            </li>
                                            <li>
                                                <Link to="/fomopea-history">Notre histoire</Link>
                                            </li>
                                            <li>
                                                <Link to="/fomopea-dynasty">La dynastie royale</Link>
                                            </li>
                                            <li>
                                                <Link to="/chieftaincy">La chefferie et les notables</Link>
                                            </li>
                                            <li>
                                                <Link to="/neighborhoods">Les différents quartiers</Link>
                                            </li>
                                            <li>
                                                <Link to="/associations">La vie associative</Link>
                                            </li>

                                        </ul>

                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-2">
                                <div className="footer-widget">
                                    <div className="footer-widget-department">
                                        <h4 className="footer-widget-title">L'ADGF</h4>
                                        <ul className="list-unstyled">
                                            <li>
                                                <Link to="/about-adgf">A propos de nous</Link>
                                            </li>
                                            <li>
                                                <Link to="/commissions-adgf">Les différentes commissions</Link>
                                            </li>
                                            <li>
                                                <Link to="/realisations-and-projects">Réalisations et projets</Link>
                                            </li>
                                            <li>
                                                <Link to="/events">Les évènements</Link>
                                            </li>
                                            <li>
                                                <Link to="/news">Les articles</Link>
                                            </li>
                                       
                                        </ul>

                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-3">
                                <div className="footer-widget">
                                    <div className="footer-widget-contact">
                                        <h4 className="footer-widget-title">Nous contacter</h4>
                                        <p>
                                            FOMOPEA
                                            <br />
                                            Fokoué,Menoua,Cameroun
                                        </p>
                                    </div>

                                    <div className="footer-widget-contact-list">
                                        <i className="fa-solid fa-money-bill-transfer" />
                                        <div className="footer-widget-contact-item">
                                            <Link to="/contribute">Contribuer</Link>
                                        </div>
                                    

                                    </div>

                                    <div className="footer-widget-contact-list">
                                        <i className="fa-solid fa-envelope" />
                                        <div className="footer-widget-contact-item">
                                            <Link to="mailto:needhelp@company.com">info@adgfomopea.org</Link>
                                        </div>

                                    </div>

                                    <div className="footer-widget-contact-list">
                                        <i className="fa-solid fa-phone" />
                                        <div className="footer-widget-contact-item">
                                            <Link to="tel:+92-003-68-090">+237 6 71 44 31 17</Link>
                                        </div>
                                      

                                    </div>

                               

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className="bottom-footer">
                    <div className="conatiner">

                        <p>© Copyright hepomenzengue@adgf</p>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Main