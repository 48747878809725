import React from 'react'

import OwlCarousel from 'react-owl-carousel';
import ReactMarkdown from "react-markdown";
import apiConstant from "../../../assets/constants/strapiBaseUrl"


function Main(props) {
    const { mainDetailImage, eventLocation, eventDate, eventTitle, eventContent, galleryImages } = props;

    const settings = {
        items: 3,
        nav: false,
        loop: true,
        margin: 10,
        autoplay: true,
        smartSpeed: 2000,
        responsive: {
            0: {
                items: 1,
            },
            767: {
                items: 2
            },
            1199: {
                items: 3
            }
        }
    };

    return (
        <>
            <section className="event-details-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="event-details-inner-box">
                                {mainDetailImage && <img src={mainDetailImage} className="img-fluid" />}
                                <div className="event-details-meta">
                                    <div className="event-details-meta-number">
                                        {eventDate && <span>{`${new Date(eventDate).toLocaleDateString('fr-FR', {
                                            day: 'numeric',
                                        })}`}</span>}
                                    </div>

                                    <div className="event-details-meta-date">
                                        {eventDate && <span>{`${new Date(eventDate).toLocaleDateString('fr-FR', {
                                            year: 'numeric',
                                            month: 'long',
                                        })}`}</span>}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="col-lg-8">
                            <div className="event-details-content-box">
                                {eventTitle && <h4>{eventTitle}</h4>}
                                {eventContent && <ReactMarkdown children={eventContent} />}


                                {galleryImages && Array.isArray(galleryImages) && (
                                    <div className="event-details-gallery-box">
                                        <h3>Quelques images illustratives</h3>
                                        <OwlCarousel className="event-details-carousel owl-carousel owl-theme" {...settings}>
                                            {galleryImages.filter((item) => item).map((item, key) => (
                                                <div className="item" key={key}>
                                                    <img src={`${apiConstant}${item.attributes.formats.medium.url}`} className="img-fluid" alt={`img-${key}`} />
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar">
                                <div className="sidebar-widget-event-meta-box">
                                    <div className="sidebar-widget-event-meta-details">
                                        <div className="sidebar-widget-event-box">
                                            <h6>Heure:</h6>
                                            {eventDate && <p> {`${new Date(eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}</p>}
                                        </div>

                                        <div className="sidebar-widget-event-box">
                                            <h6>Date:</h6>
                                            {eventDate && <p> {`${new Date(eventDate).toLocaleDateString('fr-FR', {
                                                 weekday: 'long',
                                                 year: 'numeric',
                                                 month: 'long',
                                                 day: 'numeric',
                                            })}`}</p>}
                                        </div>

                                        <div className="sidebar-widget-event-box">
                                            <h6>Lieu:</h6>
                                            {eventLocation && <p> {eventLocation}</p>}
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}

export default Main