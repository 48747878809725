import React, { useEffect,useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Main from '../mendestinationdetails/Main'
import VoidList from '../../voidList';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  getAllDestinations
} from "../../../api/api";
import apiConstant from "../../../assets/constants/strapiBaseUrl"
import {
  ResultCardSkeleton
} from "../../../components/skeletonLoaders";


function DestinationDetails() {
  const { uuid } = useParams();
  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getAllDestinations()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setDestination(res.data.data.find(n => n.attributes.uuid === uuid));
        }
      })
      .catch((error) => { setError(error); })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  if (destination) {

    document.getElementById("title").innerHTML = `Site touristique  || ${destination.attributes?.name.toString().replace(/\s/g, '-')}`;
  }


  return (
    <>
      <Header />

      <div className="page-wrapper">
        {destination && <Bredcom
          firstPageRoute="/"
          firstPage="Accueil"
          secondPageRoute="/destinations"
          secondPage="Espaces touristiques"
          thirdPage="Description détaillée "
        /*  titledown={news.attributes?.newstitle.toString()} */

        />}

        {loading && !error && <ResultCardSkeleton />}
        {!loading && !error && Object.keys(destination).length > 0 &&
          <Main name={destination.attributes?.name}
            content={destination.attributes?.content}
            indication={destination.attributes?.indication}
            galleryImages={destination.attributes?.galleryImages.data}
          />}
        {!loading && !error && Object.keys(destination).length === 0 && <VoidList
          message={"Désolé, Ce site touristique n'existe oas. Merci de réessayer plus tard"}
        />}
        {error && <VoidList
          message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
        />}
      </div>
    </>
  )
}

export default  DestinationDetails