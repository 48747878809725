import React , { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';
import Bgone from '../../assets/image/bg/mont_neyang.jpg';
import Bgtwo from '../../assets/image/bg/chefferie.jpeg';
import OwlCarousel from 'react-owl-carousel';

import { getAllDestinations } from '../../api/api';
import apiConstant from "../../assets/constants/strapiBaseUrl"
import {
  BlockListSkeleton
} from "../../components/skeletonLoaders";
import VoidList from '../voidList';

function Slider() {
  const [destinationsList, setDestinationsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const settings = {
    loop: true,
    autoplay: true,
    nav: true,
    items: 1,
    dots: false,
    navText: [
      '<i class="fa-solid fa-arrow-left-long"></i>',
      '<i class="fa-solid fa-arrow-right-long"></i>',
    ],
  };
  useEffect(() => {
    setLoading(true);
    getAllDestinations()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setDestinationsList(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <section className="main-slider">
        {loading && !error && <BlockListSkeleton />}
        {!loading && !error && destinationsList && destinationsList.length > 0 &&
          <OwlCarousel
            className="main-slider-swiper owl-carousel owl-theme"
            {...settings}
          >


            {destinationsList && destinationsList.length > 0 &&
              destinationsList.map((destination, key) => (
                <div className="item">
                  {destination.attributes.listImage && <div
                    className="item-slider-bg"
                    style={{ backgroundImage: `url(${apiConstant}${destination.attributes.listImage.data?.attributes.formats.small.url})` }}
                  />}
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="slider-content">
                          {' '}
                          <div className="slider-tagline">
                            {destination.attributes.indication && <span>{destination.attributes.indication}</span>}
                          </div>
                          {destination.attributes.name && <h1 className="section-title">
                          {destination.attributes.name}

                            {/* {destination.attributes.name.split(" ").slice(0, 2).join(" ")} <br />
                            {destination.attributes.name.split(" ").substring(2).join(" ")} */}
                          </h1>}
                          <Link to={`/destinationdetails/${destination.attributes.uuid}`} className="btn btn-primary">
                            Découvrir
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}

            {!loading && !error && (!destinationsList || destinationsList.length === 0) && <VoidList
              message={"Désolé, ce site n'existe pas. Merci de réessayer plus tard"}
            />}
            {error && <VoidList
              message={"Désolé, une erreur s'est produite. Merci de réessayer plus tard"}
            />}





          </OwlCarousel>}
      </section>
    </>
  );
}

export default Slider;
